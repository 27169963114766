<template>
  <div class="box-card" style="text-align: left">
    <el-form :model="addForm" ref="addFormRefs" :rules="addFormRules" label-width="120px" class="addForm">
      <el-form-item label="集团名称：" prop="group_info.group_name">
        <el-input placeholder="请输入集团名称" autocomplete="off" maxlength="30" :value="addForm.group_info.group_name"
          @input="(e) => (addForm.group_info.group_name = divideSymbol(e))"></el-input>
      </el-form-item>
      <el-divider></el-divider>
      <el-form-item label="平台域名：" prop="group_info.domain_address">
        <el-select v-model="addForm.group_info.domain_default" placeholder="请选择平台域名" @change="domainDefaultChange">
          <el-option label="默认域名" :value="false"></el-option>
          <el-option label="自定义" :value="true"></el-option>
        </el-select>
        <el-input placeholder="请输入域名" class="domainSuffix_box" v-model="addForm.group_info.domain_address"
          v-if="addForm.group_info.domain_default" @input="(e) => (addForm.group_info.domain_address = divideSymbol(e))">
          <template slot="append">{{ domainSuffix }}</template>
        </el-input>
        <el-input v-model="addForm.group_info.domain_address" readonly style="width:180px;" v-else></el-input>
      </el-form-item>
      <el-form-item label="平台名称：" prop="group_info.platform_name">
        <el-input placeholder="请输入平台名称" autocomplete="off" maxlength="30" :value="addForm.group_info.platform_name"
          @input="(e) => (addForm.group_info.platform_name = divideSymbol(e))"></el-input>
      </el-form-item>
      <el-form-item label="LOGO：">
        <div class="logo_upload_box">
          <el-image fit="contain" :src="addForm.group_info.logo_url" :preview-src-list="[addForm.group_info.logo_url]"
            v-if="addForm.group_info.logo_url" :z-index="9000"></el-image>
          <div class="handelButton">
            <lbButton size="small" type="warning" class="deleteLogo" @click="deleteLogo"
              v-if="addForm.group_info.logo_url">删除</lbButton>
            <el-upload class="logo-upload" action="''" :http-request="requestUploadImg" :show-file-list="false"
              accept="image/gif,image/jpeg,image/jpg,image/png" :before-upload="beforeAvatarUpload2">
              <lbButton size="small" :type="addForm.group_info.logo_url ? 'warning' : 'default'">{{
                addForm.group_info.logo_url ? "重新上传" : "上传"
              }}</lbButton>
            </el-upload>
            <span class="logoinfo el-icon-warning-outline">说明:仅支持jpg,gif,jpeg,png格式的图片，大小&lt;=1MB</span>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="登录页：" v-if="addForm.group_info.domain_default">
        <ul class="theme_box">
          <li :class="[
                'img_box','hover_box',
                addForm.group_info.login_page == item ? 'is_check' : ''
              ]" v-for="(item) in loginpageList" :key="item" @click="checkLoginPage(item)">
              <div class="preview_box">
                  <lbButton size="small" type="white" class="previewLoginPage" @click="previewLoginPage">预 览</lbButton>
                </div>
            <el-image fit="contain" :src="require(`@/assets/images/group/login${item}.png`)"></el-image>
            <i class="iconfont lebo-chenggong1" v-if="addForm.group_info.login_page == item"></i>
          </li>
        </ul>
        <!-- <div class="theme_box">
            <div class="img_box">
              <el-image fit="contain" :src="require(`../../../assets/images/group/login${addForm.group_info.login_page}.png`)" :preview-src-list="[require(`../../../assets/images/group/login${addForm.group_info.login_page}.png`)]"></el-image>
            </div>
            <el-radio-group v-model="addForm.group_info.login_page">
              <el-radio :label="1">样式1</el-radio>
              <el-radio :label="2" :disabled="!addForm.group_info.domain_default">样式2</el-radio>
              <el-radio :label="3" :disabled="!addForm.group_info.domain_default">样式3</el-radio>
            </el-radio-group>
          </div> -->
      </el-form-item>
      <el-form-item label="主题：">
        <ul class="theme_box">
          <li :class="[
                'img_box',
                addForm.group_info.theme == item ? 'is_check' : '',
              ]" v-for="item in themeList" :key="item" @click="addForm.group_info.theme = item">
            <el-image fit="contain" :src="require(`@/assets/images/group/theme${item}.png`)"></el-image>
            <i class="iconfont lebo-chenggong1" v-if="addForm.group_info.theme == item"></i>
          </li>
        </ul>
        <!-- <div class="theme_box">
            <div class="img_box">
              <el-image fit="contain" :src="require(`../../../assets/images/group/theme${addForm.group_info.theme}.png`)" :preview-src-list="[require(`../../../assets/images/group/theme${addForm.group_info.theme}.png`)]"></el-image>
            </div>
            <el-radio-group v-model="addForm.group_info.theme">
              <el-radio :label="1">样式1</el-radio>
              <el-radio :label="2">样式2</el-radio>
              <el-radio :label="3">样式3</el-radio>
              <el-radio :label="4">样式4</el-radio>
            </el-radio-group>
          </div> -->
      </el-form-item>
      <div style="display: flex">
        <el-form-item label="平台菜单包：" prop="menus_custom">
          <lbButton size="small" :type="addForm.menus_custom.children &&
                addForm.menus_custom.children.length > 0 ? 'warning' : 'default'"
            @click="isConfigMenusCustom = true">{{
              addForm.menus_custom.children &&
              addForm.menus_custom.children.length > 0
              ? "修改配置"
              : "配置"
            }}</lbButton>
        </el-form-item>
        <el-form-item label="大屏：">
          <lbButton size="small" :type="addForm.screen_custom.length > 0 ? 'warning' : 'default'"
            @click="isConfigScreenCustom = true">{{
              addForm.screen_custom.length > 0 ? "修改配置" : "配置"
            }}</lbButton>
        </el-form-item>
        <el-form-item label="app功能包：" prop="">
          <lbButton size="small" :type="addForm.plate_custom ? 'warning' : 'default'" @click="isConfigPlateCustom = true">
            {{
              addForm.plate_custom ? "修改配置" :
              "配置"
            }}</lbButton>
        </el-form-item>
      </div>
      <!-- <el-form-item label="场景配置：">
        <el-select v-model="addForm.group_info.is_scene" placeholder="请选择场景配置">
          <el-option label="开启" :value="true"></el-option>
          <el-option label="关闭" :value="false"></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="手机端：">
        <el-checkbox-group v-model="addForm.group_info.phone_client">
          <el-checkbox v-for="item in getOptions('GroupPhoneClient')" :key="item.value" :label="item.value">{{
            item.name
          }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="快捷入口：">
        <el-checkbox-group v-model="addForm.group_info.shortcut">
          <el-checkbox v-for="item in getOptions('GroupShortCut')" :key="item.value" :label="item.value">{{
            item.name
          }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <!-- 是否开启实验室 -->
      <!-- <el-form-item label="实验室：">
        <el-select v-model="addForm.group_info.is_laboratory" placeholder="请选择是否开启实验室">
          <el-option label="开启" :value="true"></el-option>
          <el-option label="关闭" :value="false"></el-option>
        </el-select>
      </el-form-item> -->
    </el-form>
    <!-- <lbButton type="goBack" icon="back" class="checkButton2" @click="defaultForm">返 回</lbButton> -->
    <lbButton type="default" fill icon="confirm" class="checkButton" @click="onSubmit()" v-preventReClick>保 存</lbButton>
    <ConfigMenusCustom :menusCustom="addForm.menus_custom" :addNewId="id" @save="saveConfigMenusCustom"
      @close="isConfigMenusCustom = false" v-if="isConfigMenusCustom"></ConfigMenusCustom>
    <ConfigScreenCustom :screenCustom="addForm.screen_custom" @save="saveConfigBigScreenCustom"
      @close="isConfigScreenCustom = false" @removeAll="removeAllScreenCustom" v-if="isConfigScreenCustom"></ConfigScreenCustom>
    <ConfigPlateCustom :plateCustom="addForm.plate_custom" @save="savePlateCustom" @close="isConfigPlateCustom = false"
      v-if="isConfigPlateCustom"></ConfigPlateCustom>
    <!-- 登录页页面预览弹框 -->
    <lebo-dialog append-to-body title="预览" :isShow="previewDialog" @close="previewDialog=false" footerSlot>
      <el-image fit="contain"
        :src="require(`@/assets/images/group/login${this.addForm.group_info.login_page}.png`)"></el-image>
    </lebo-dialog>
  </div>
</template>
<script>
import systemapi from '@/api/systemapi'
import { lazyAMapApiLoaderInstance } from 'vue-amap'
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import ConfigMenusCustom from '@/components/configMenusCustom/index.vue'
import ConfigScreenCustom from '@/components/configScreenCustom/index.vue'
import ConfigPlateCustom from '@/components/configPlateCustom/index.vue'
export default {
  props: ['id'],
  components: {
    ConfigMenusCustom,
    ConfigScreenCustom,
    ConfigPlateCustom
  },
  data () {
    var requiredVla = (rule, value, callback) => {
      if (value.children && value.children.length) {
        callback()
      } else {
        callback(new Error('请配置菜单'))
      }
    }
    // 自定义校验app功能包
    var requiredVla2 = (rule, value, callback) => {
      if (value.children && value.children.length) {
        callback()
      } else {
        callback(new Error('请配置app功能包'))
      }
    }
    return {
      loginpageList: [1, 2, 3, 4],
      themeList: [1, 2, 3, 4],
      addForm: {
        group_info: {
          group_name: '', // 集团名称
          domain_default: false, // 是否域名自定义
          domain_address: '', // 域名地址
          platform_name: '', // 平台名称
          logo_url: '', // logo地址
          login_page: 1, // 登录页
          theme: 1,
          // is_scene: false, // 是否配置场景
          phone_client: [], // 手机端 //对应枚举GroupPhoneClient
          shortcut: [] // 快捷入口 //对应枚举 GroupShortCut
          // is_laboratory: false // 是否开启实验室
        }, // 集团信息
        is_update_menus_custom: false, // 是否修改菜单，控制编辑时是否修改菜单自定义数据
        menus_custom: {}, // 集团菜单自定义信息
        screen_custom: [], // 集团大屏自定义信息(只需要已勾选的数据，is_open全为false)
        plate_custom: {} // 集团APP板块自定义信息
      },
      addFormRules: {
        group_info: {
          group_name: [
            { required: true, message: '请输入集团名称', trigger: 'blur' }
          ],
          platform_name: [
            { required: true, message: '请输入平台名称', trigger: 'blur' }
          ],
          domain_address: [
            { required: true, message: '请输入域名地址', trigger: 'blur' }
          ]
        },
        menus_custom: [
          { required: true, validator: requiredVla, trigger: 'blur' }
        ],
        plate_custom: [
          { required: true, validator: requiredVla2, trigger: 'blur' }
        ]
      },
      // 是否展示配置平台菜单包对话框
      isConfigMenusCustom: false,
      isConfigScreenCustom: false,
      isConfigPlateCustom: false,
      domainSuffix: '',
      // 默认域名地址
      domainAddress: '',
      previewDialog: false // 预览登录页弹出框
    }
  },
  created () {
    this.fnGetCustomDomainSuffix()
    if (this.id) {
      this.fngetGroupDetail()
    }
    this.addForm.group_info.login_page = this.platformInfo.login_page
  },
  mounted () {
    if (JSON.stringify(this.addForm.plate_custom) === '{}') {
      this.fngetAppPlate()
    }
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus', 'platformInfo']),
    ...mapGetters(['isAdmin', 'getOptions', 'getWayName'])
  },
  watch: {},
  methods: {
    ...mapMutations(['setQueryStatus']),
    // 获取集团详情
    async fngetGroupDetail () {
      const res = await systemapi.getGroupDetail({
        group_id: this.id
      })
      if (res && res.Code === 200) {
        this.addForm = JSON.parse(JSON.stringify(res.Data))
        console.log('this.addForm', this.addForm)
        console.log('this.addForm.plate_custom', this.addForm.plate_custom)
        if (this.addForm.group_info.domain_default) {
          this.addForm.group_info.domain_address =
            this.addForm.group_info.domain_address.replace(
              '.' + this.domainAddress,
              ''
            )
        } else {
          this.addForm.group_info.domain_address =
            res.Data.group_info.domain_address || this.domainAddress
        }
        if (this.addForm.plate_custom === null) {
          this.addForm.plate_custom = {}
          console.log('this.addForm.plate_custom', this.addForm.plate_custom)
        }
        if (this.addForm.group_info.platform_name === '') {
          this.addForm.group_info.platform_name = '平台名称0002'
        }
        if (this.addForm.group_info.phone_client === null) {
          this.addForm.group_info.phone_client = []
        }
        if (this.addForm.group_info.shortcut === null) {
          this.addForm.group_info.shortcut = []
        }
        this.addForm.is_update_menus_custom = false
      }
    },
    // 获取自定义域名后缀
    async fnGetCustomDomainSuffix () {
      const res = await systemapi.getCustomDomainSuffix()
      if (res && res.Code === 200) {
        this.domainSuffix = '.' + res.Data
        if (!this.addForm.group_info.domain_default) {
          this.addForm.group_info.domain_address = res.Data
          this.domainAddress = res.Data
        }
      }
    },
    domainDefaultChange (val) {
      if (val) {
        this.addForm.group_info.domain_address = ''
        this.addForm.group_info.login_page = 1
      } else {
        this.addForm.group_info.domain_address = this.domainAddress
      }
    },
    checkLoginPage (item) {
      this.addForm.group_info.login_page = item
    },
    // 返回
    defaultForm () {
      this.setQueryStatus(2)
      this.$emit('closeDialog', false)
    },
    // 上传图片
    requestUploadImg (item) {
      const indexpoint = item.file.name.lastIndexOf('.')
      const imageName = item.file.name.substr(indexpoint)
      // console.log("-----...-----" + imageName);
      // 预览图片
      const src = window.URL.createObjectURL(item.file)
      // console.log("==-=-=src-=-=", src);
      // 将图片文件转化成base64格式图片
      var reader = new FileReader()
      reader.onload = async (e) => {
        // e.target.result  就是从本地读取的图片的base64格式,将它上传给服务器即可
        // 使用axios的post方法上传即可
        var base64image = e.target.result
        // console.log("==-=-base64image=-=-", base64image);
        const res = await systemapi.updataIcon({
          name: item.file.name,
          strbase64: [base64image]
        })
        var url = ''
        if (res.data && res.data.length > 0) {
          url = res.data[0].FilePath
        } else {
          this.$msg.warning('你的图片格式有误请重新选择!')
        }
        this.addForm.group_info.logo_url = url
      }
      reader.readAsDataURL(item.file)
    },
    // 保存自定义菜单
    saveConfigMenusCustom (item) {
      if (
        this.id &&
        JSON.stringify(this.addForm.menus_custom) !== JSON.stringify(item)
      ) {
        this.addForm.is_update_menus_custom = true
      } else {
        this.addForm.is_update_menus_custom = false
      }
      this.addForm.menus_custom = item
      // this.addForm.menus_custom = item;
    },
    // 保存自定义大屏
    saveConfigBigScreenCustom (list) {
      console.log('saveConfigBigScreenCustom---', list)
      this.addForm.screen_custom = list
    },
    // 删除所有已配置大屏
    removeAllScreenCustom () {
      this.addForm.screen_custom = []
    },
    // 保存自定义app包
    savePlateCustom (item) {
      console.log('itemsavePlateCustom', item)
      this.addForm.plate_custom = item
    },
    // 保存提交
    onSubmit () {
      this.$refs.addFormRefs.validate((valid) => {
        if (valid) {
          var obj = JSON.parse(JSON.stringify(this.addForm))
          if (obj.screen_custom.length > 0) {
            obj.screen_custom.forEach(element => {
              element.children.forEach(item => {
                delete item.graph_type
              })
            })
          }
          if (this.addForm.group_info.domain_default) {
            obj.group_info.domain_address =
              this.addForm.group_info.domain_address + this.domainSuffix
          } else {
            obj.group_info.domain_address = this.domainAddress
          }
          if (this.id) {
            obj.group_id = this.id
            this.fnupdataGroup(obj)
          } else {
            this.fnaddGroup(obj)
          }
        }
      })
    },
    async fnaddGroup (obj) {
      const res = await systemapi.addGroup(obj)
      if (res && res.Code === 200) {
        this.$emit('closeDialog', false)
      }
    },
    async fnupdataGroup (obj) {
      const res = await systemapi.updataGroup(obj)
      if (res && res.Code === 200) {
        this.defaultForm()
      }
    },
    // 删除上传logo
    deleteLogo () {
      this.addForm.group_info.logo_url = ''
    },
    // 打开登录页预览弹框
    previewLoginPage () {
      this.previewDialog = true
    },
    // 获取app模板列表
    async fngetAppPlate () {
      const res = await systemapi.getResourceAppPlatelist({
        app_type: 1
      })
      if (res && res.Code === 200) {
        this.addForm.plate_custom = {
          app_type: 1,
          children: res.Data.children
        }
      }
    }
  }
}
</script>
<style scoped lang="less">
.addForm {
  max-height: 650px;
  overflow-y: auto;

  .el-form-item {
    margin-right: 180px;
  }

  /deep/ .el-form-item__content {
    width: 100%;
  }

  /deep/ .el-select,
  /deep/.el-input {
    width: 400px;
    margin-right: 10px;
  }

  /deep/ .logo_upload_box {
    // display: flex;
    // align-items: center;
    .handelButton {
      display: flex;
      align-items: center;

      .logoinfo {
        font-size: 14px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #CCCCCC;

        &::before {
          margin-right: 4px;
        }
      }
    }

    .el-image {
      height: 100px;
      margin-right: 10px;
      background-color: #eee;

      img {
        width: auto;
      }
    }
  }

  /deep/ .theme_box {
    display: flex;
    align-items: end;
    position: relative;
    .hover_box:hover{
    .preview_box{
      display: flex;
    align-items: center;
    justify-content: center;
    }
  }
  .preview_box{
    display: none;
    position: absolute;
    text-align: center;
    width: 100%;
    height: 100%;
    // background-color: RGBA(230, 230, 230, .3);
    background-color: rgba(0,0,0,.3);
    z-index: 2000;
  }
    .img_box {
      width: 198px;
      height: 108px;
      margin-right: 10px;
      border: 1px solid #ccc;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      cursor: pointer;
      position: relative;

      .el-image {
        width: 100%;
        height: 100%;
      }

      .iconfont {
        position: absolute;
        bottom: 5px;
        right: 5px;
        font-size: 24px;
        color: #01a6fb;
      }
    }

    .is_check {
      border: 1px solid #01a6fb;
    }

    .is_disabled {
      opacity: 0.7;
    }
  }

  /deep/ .domainSuffix_box {
    .el-input__inner {
      border-right: none;
      padding-right: 0px;
    }

    .el-input-group__append {
      background-color: #fff;
      border-left: none;
      padding-left: 0px;
    }

    &:hover {
      .el-input-group__append {
        border-color: #c0c4cc;
      }
    }

    & :focus {
      .el-input-group__append {
        border-color: #01a6fb;
      }
    }
  }

  /deep/ .is-error {
    .domainSuffix_box {
      .el-input-group__append {
        border-color: #f56c6c;
      }
    }
  }
}

.box-card {
  position: relative;
  padding-bottom: 30px;

  .checkButton {
    position: absolute;
    right: 10px;
  }

  .checkButton2 {
    position: absolute;
    right: 110px;
  }
}
</style>
