<template>
  <lebo-dialog append-to-body title="配置APP功能包" :isShow="isConfigPlateCustom" width="70%" top="10vh" @close="closeHandle" footerSlot>
    <!-- <div>{{plate_custom}}</div> -->
    <config ref="configRef" :list="tableData"></config>
    <div slot="footer" class="dialog-footer">
      <lbButton type="warning" fill @click="openPlateTemplate">从模板导入</lbButton>
      <div>
        <!-- <lbButton type="goBack" icon="back" @click="isConfigPlateCustom = false">返 回</lbButton> -->
        <lbButton type="default" fill icon="confirm" @click="onSubmit">保 存</lbButton>
      </div>
    </div>
    <lebo-dialog append-to-body title="平台菜单模板" :isShow="isAPPTemplate" width="30%" @close="isAPPTemplate=false" footerSlot>
      <el-table class="tablestyle" ref="multipleTable" :data="appTemplateList" align="center" header-align="center" tooltip-effect="dark" border stripe>
        <el-table-column prop="pack_name" label="APP功能包"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <lbButton type="info" icon="daoru1" hint="确定导入" @click="importAPP(scope.row)"></lbButton>
          </template>
        </el-table-column>
      </el-table>
    </lebo-dialog>
  </lebo-dialog>
</template>
<script>
import systemApi from '@/api/systemapi'
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import config from '@/components/appCustom/config.vue'
export default {
  components: {
    config
  },
  props: {
    plateCustom: { type: Object }
  },
  data () {
    return {
      plate_custom: {},
      tableData: [],
      isConfigPlateCustom: true,
      appTemplateList: [],
      isAPPTemplate: false
    }
  },
  created () {
    if ((JSON.stringify(this.plateCustom) !== '{}')) {
      this.plate_custom = JSON.parse(JSON.stringify(this.plateCustom))
      this.tableData = this.plate_custom.children
    } else {
      this.fngetAppPlate()
    }
  },
  mounted () { },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['isAdmin', 'getOptions', 'getWayName'])
  },
  watch: {},
  methods: {
    async openPlateTemplate () {
      const res = await systemApi.getAppTemplate()
      this.appTemplateList = res && res.Code === 200 ? res.Data : []
      this.isAPPTemplate = true
    },
    // 获取app模板列表
    async fngetAppPlate () {
      const res = await systemApi.getResourceAppPlatelist({
        app_type: 1
      })
      if (res && res.Code === 200) {
        this.tableData = res.Data.children
      }
    },
    importAPP (item) {
      this.plate_custom = item
      this.tableData = item.children
      this.isAPPTemplate = false
    },
    closeHandle () {
      this.isConfigPlateCustom = false
      this.$emit('close')
    },
    onSubmit () {
      var newArr = this.$refs.configRef.Gettabledata()
      this.plate_custom.children = newArr
      this.plate_custom.app_type = 1
      this.$emit('save', this.plate_custom)
      this.isConfigPlateCustom = false
    }
  }
}
</script>
<style scoped lang="less">
.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tablestyle {
  /deep/ .el-table__body-wrapper {
    max-height: 500px;
    overflow-y: auto;
  }
}
</style>
