<template>
  <div>
    <lebo-dialog append-to-body title="配置平台菜单包" :isShow="isConfigMenusCustom" width="61%" @close="closeHandle" footerSlot>
    <Config ref="ConfigRef" :menuList="menus_custom.children" v-if="menus_custom"></Config>
    <div slot="footer" class="dialog-footer">
      <lbButton type="warning" fill @click="openMenusTemplate">从模板导入</lbButton>
      <div>
        <!-- <lbButton type="goBack" icon="back" @click="isConfigMenusCustom = false">返 回</lbButton> -->
        <lbButton type="default" fill icon="confirm" @click="saveMenus()">保 存</lbButton>
      </div>
    </div>
    <lebo-dialog append-to-body title="平台菜单包模板" :isShow="isMenusTemplate" width="30%" @close="isMenusTemplate=false" footerSlot>
      <el-table class="tablestyle" ref="multipleTable" :data="menusTemplateList" align="center" header-align="center" tooltip-effect="dark" border stripe>
        <el-table-column prop="resource_name" label="平台菜单包"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <lbButton type="info" icon="daoru1" hint="确定导入" @click="importMenus(scope.row)"></lbButton>
          </template>
        </el-table-column>
      </el-table>
    </lebo-dialog>
  </lebo-dialog>
  <lebo-dialog width="30%" title="友情提示" :isShow="isFriendshipToSubmit" @close="isFriendshipToSubmit = false" footerSlot>
      <div style="text-align: left;text-indent: 2em; ">您修改了平台菜单包，会删除当前集团下所有角色，您确定修改吗？</div>
      <div slot="footer" class="isFriendship-footer">
        <!-- <lbButton type="goBack" icon="back" @click="isFriendshipToSubmit = false">再想一下</lbButton> -->
        <lbButton type="default" fill icon="confirm" @click="onSubmit" class="btnButton">确 定</lbButton>
      </div>
    </lebo-dialog>
  </div>
</template>
<script>
import systemApi from '@/api/systemapi'
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import Config from '@/components/menuCustom/config.vue'
export default {
  props: {
    menusCustom: { type: Object },
    addNewId: { type: String }
  },
  components: {
    Config
  },
  data () {
    return {
      menus_custom: {},
      isConfigMenusCustom: true,
      isMenusTemplate: false,
      menusTemplateList: [], // 模板列表
      isFriendshipToSubmit: false
    }
  },
  created () {
    console.log('this.addNewId', this.addNewId)
    console.log('created----', this.menusCustom)
    if (this.menusCustom) {
      this.menus_custom = JSON.parse(JSON.stringify(this.menusCustom))
    }
  },
  mounted () {
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['isAdmin', 'getOptions', 'getWayName'])
  },
  watch: {},
  methods: {
    // 点击从模板导入
    async openMenusTemplate () {
      const res = await systemApi.getMenusTemplateList()
      console.log(res)
      this.menusTemplateList = res && res.Code === 200 ? res.Data : []
      this.isMenusTemplate = true
    },
    // 导入菜单
    importMenus (item) {
      this.menus_custom = item
      this.isMenusTemplate = false
    },
    // 保存
    onSubmit () {
      this.menus_custom.children = this.$refs.ConfigRef.configList
      this.$emit('save', this.menus_custom)
      this.isConfigMenusCustom = false
    },
    closeHandle () {
      this.$emit('close')
      this.isConfigMenusCustom = false
    },
    saveMenus () {
      if (this.addNewId) {
        this.isFriendshipToSubmit = true
      } else {
        this.onSubmit()
      }
    }
  }
}
</script>
<style scoped lang="less">
.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;

}
.tablestyle {
  /deep/ .el-table__body-wrapper {
    max-height: 500px;
    overflow-y: auto;
  }
}

</style>
