<template>
  <!-- 系统 —— 集团管理 -->
  <div style="margin-top: 20px;">
    <!-- 查询表单 -->
    <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
      @submit.native.prevent>
      <el-form-item label="集团：">
        <el-input maxlength="15" @keyup.enter.native="onSubmit" placeholder="请输入集团名称" :value="queryForm.group_name"
          @input="(e) => (queryForm.group_name = validSpace(e))"></el-input>
      </el-form-item>
      <el-form-item>
        <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
      </el-form-item>
    </el-form>
    <le-card title="集团管理">
      <template slot="heard-right">
        <div class="right">
          <lbButton type="err" icon="shanchu1" @click="deletefu">删除</lbButton>
          <lbButton icon="xinzeng" @click="handelGroupManage('')">新增</lbButton>
        </div>
      </template>
      <div class="box-card" style="text-align: left">
      <!-- 表格 -->
      <!-- 使用插件生产表格 -->
      <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @selection-change="selectionChange"
        @size-change="sizeChange" @current-change="currentChange">
        <template slot-scope="{row}" slot="user_count">
          <el-link type="primary" @click="displayList(row, '用户数', 1)" style="margin-right: 10px"
            v-if="row.user_count > 0">{{ row.user_count }}</el-link>
          <span v-else>{{ row.user_count }}</span>
        </template>
        <template slot-scope="{row}" slot="in_parking_count">
          <el-link type="primary" @click="displayList(row, '路内停车场', 2)" style="margin-right: 10px"
            v-if="row.in_parking_count > 0">{{ row.in_parking_count }}</el-link>
          <span v-else>{{ row.in_parking_count }}</span>
        </template>
        <template slot-scope="{row}" slot="off_parking_count">
          <el-link type="primary" @click="displayList(row, '路外停车场', 3)" style="margin-right: 10px"
            v-if="row.off_parking_count > 0">{{ row.off_parking_count }}</el-link>
          <span v-else>{{ row.off_parking_count }}</span>
        </template>
        <template slot-scope="{row}" slot="organ_count">
          <el-link type="primary" @click="displayList(row, '组织', 5)" style="margin-right: 10px"
            v-if="row.organ_count > 0">{{ row.organ_count }}</el-link>
          <span v-else>{{ row.organ_count }}</span>
        </template>
        <template slot-scope="{row}" slot="scene_count">
          <el-link type="primary" @click="displayList(row, '监控场景', 4)" style="margin-right: 10px"
            v-if="row.scene_count > 0">{{ row.scene_count }}</el-link>
          <span v-else>{{ row.scene_count }}</span>
        </template>
        <template slot-scope="{row}" slot="create_time">
          <span>{{ validDateTime(row.create_time) }}</span>
        </template>
        <template slot-scope="{row}" slot="menu">
          <lbButton type="warning" icon="bianji" hint="编辑" @click="handelGroupManage(row._id)"></lbButton>
        </template>
      </avue-crud> -->
      <el-table class="tablestyle" ref="multipleTable" :data="tableData" align="center" header-align="center"
        tooltip-effect="dark" border stripe @selection-change="handleSelectionChange" >
        <el-table-column type="selection" width="50" align="center" header-align="center"></el-table-column>
        <el-table-column prop="group_name" label="集团"></el-table-column>
        <el-table-column label="用户数">
          <template slot-scope="scope">
            <el-link type="primary" @click="displayList(scope.row, '用户数', 1)" style="margin-right: 10px"
              v-if="scope.row.user_count > 0">{{ scope.row.user_count }}</el-link>
            <span v-else>{{ scope.row.user_count }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="" label="路内停车场">
          <template slot-scope="scope">
            <el-link type="primary" @click="displayList(scope.row, '路内停车场', 2)" style="margin-right: 10px"
              v-if="scope.row.in_parking_count > 0">{{ scope.row.in_parking_count }}</el-link>
            <span v-else>{{ scope.row.in_parking_count }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="" label="路外停车场">
          <template slot-scope="scope">
            <el-link type="primary" @click="displayList(scope.row, '路外停车场', 3)" style="margin-right: 10px"
              v-if="scope.row.off_parking_count > 0">{{ scope.row.off_parking_count }}</el-link>
            <span v-else>{{ scope.row.off_parking_count }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="" label="组织">
          <template slot-scope="scope">
            <el-link type="primary" @click="displayList(scope.row, '组织', 5)" style="margin-right: 10px"
              v-if="scope.row.organ_count > 0">{{ scope.row.organ_count }}</el-link>
            <span v-else>{{ scope.row.organ_count }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="" label="监控场景">
          <template slot-scope="scope">
            <el-link type="primary" @click="displayList(scope.row, '监控场景', 4)" style="margin-right: 10px"
              v-if="scope.row.scene_count > 0">{{ scope.row.scene_count }}</el-link>
            <span v-else>{{ scope.row.scene_count }}</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间">
          <template slot-scope="scope">
            <span>{{ validDateTime(scope.row.create_time) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <lbButton type="warning" icon="bianji" hint="编辑" @click="handelGroupManage(scope.row._id)"></lbButton>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="TotalCount" ></el-pagination>
    </div>
    </le-card>
    <lebo-dialog append-to-body :title="detailDialogTitle" :isShow="isDetailDialog" style="text-align: start" width="33%"
      @close="isDetailDialog = false" footerSlot closeOnClickModal>
      <el-table :data="detailDialogData" border class="table_box" height="460">
        <el-table-column type="index" label="序号" width="100" align="center" header-align="center"></el-table-column>
        <el-table-column prop="name" :label="detailDialogTitle">
        </el-table-column>
      </el-table>
    </lebo-dialog>
    <!-- 新增编辑集团 -->
    <lebo-dialog append-to-body width="80%" :title="dialogGroupManageId ? '编辑集团' : '新增集团'" :isShow="showGroupManage"
      @close="showGroupManage = false" footerSlot>
      <addGroupManage @closeDialog="closeGroupManage" v-if="showGroupManage" :id="dialogGroupManageId"></addGroupManage>
    </lebo-dialog>
  </div>
</template>

<script>
import systemapi from '@/api/systemapi'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import addGroupManage from './components/addGroupManage/index.vue'
export default {
  components: { addGroupManage },
  data () {
    const checkUser = (rule, value, callback) => {
      this.groupNameIsReuse(callback)
    }
    return {
      // 查询表单
      queryForm: {
        group_name: '',
        PageSize: 10,
        PageIndex: 1
      },
      // 表格数据
      tableData: [],
      TotalCount: 0,
      groupId: [],
      isDetailDialog: false,
      detailDialogData: [],
      detailDialogTitle: '',
      dialogGroupManageId: '',
      showGroupManage: false,
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        selection: true,
        selectionFixed: false, // 解除选择框冻结
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '集团',
            prop: 'group_name'
          }, {
            label: '用户数',
            prop: 'user_count',
            slot: true
          }, {
            label: '路内停车场',
            prop: 'in_parking_count',
            slot: true
          }, {
            label: '路外停车场',
            prop: 'off_parking_count',
            slot: true
          }, {
            label: '组织',
            prop: 'organ_count',
            slot: true
          }, {
            label: '监控场景',
            prop: 'scene_count',
            slot: true
          }, {
            label: '创建时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () {
    if (this.queryStatus === 2) {
      this.queryForm = this.deepClone(this.queryObj)
    }
  },
  mounted () {
    window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
    this.requestGroupList()
  },
  beforeRouteLeave (to, from, next) {
    this.setQueryObj(this.queryForm)
    this.setQueryStatus(1)
    next()
  },
  destroyed () {
    window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['getUserInfo', 'getOptions', 'getWayName'])
  },
  watch: {
  },
  methods: {
    ...mapMutations(['setQueryObj', 'setQueryStatus']),
    beforeunloadFn (e) {
      this.setQueryStatus(1)
    },
    // 获取集团列表
    async requestGroupList () {
      const res = await systemapi.getGroupList(this.queryForm)
      if (res && res.Code === 200) {
        this.tableData = res.Data.DataList
        this.TotalCount = res.Data.TotalCount
      }
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
      // console.log("-=-=-=-=res=", JSON.stringify(res));
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.requestGroupList()
    },
    // 每页条数改变
    handleSizeChange (size) {
      this.queryForm.PageSize = size
      this.requestGroupList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.requestGroupList()
    // },
    // 页码改变
    handleCurrentChange (page) {
      this.queryForm.PageIndex = page
      this.requestGroupList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.requestGroupList()
    // },
    // // 选中的数据
    // selectionChange (data) {
    //   if (data.length > 0) {
    //     var newArray = []
    //     for (var i = 0; i < data.length; i++) {
    //       newArray.push(data[i]._id)
    //     }
    //     this.groupId = newArray
    //   } else {
    //     this.groupId = []
    //   }
    // },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (data) {
      if (data.length > 0) {
        var newArray = []
        for (var i = 0; i < data.length; i++) {
          newArray.push(data[i]._id)
        }
        this.groupId = newArray
      } else {
        this.groupId = []
      }
    },
    // 删除前
    deletefu () {
      var that = this
      if (this.groupId.length === 0) {
        this.$msg.warning('请选择要删除的条目！')
        return
      }
      this.$confirm('此操作将永久删除数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          that.requestDeleteGroup()
        })
        .catch(() => {
          this.$msg.info('已取消删除')
        })
    },
    // 删除集团请求
    async requestDeleteGroup () {
      const res = await systemapi.deleteGroup({
        _ids: this.groupId
      })
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      }
      this.requestGroupList()
    },
    // 查看各模块数量详情
    async displayList (row, title, index) {
      // console.log(row);

      const newArray = []
      switch (index) {
        case 1: { // 用户数据
          const res1 = await systemapi.getUserListByGropId({
            group_id: row._id
          })
          if (res1 && res1.Code === 200 && res1.Data.length > 0) {
            res1.Data.forEach((element) => {
              newArray.push({ name: element.user_name })
            })
          }
          break
        }
        case 2: { // 路内停车场
          const res2 = await systemapi.getlnParkingListByGropId({
            group_id: row._id,
            parktype: 1
          })
          if (res2 && res2.Code === 200 && res2.Data.length > 0) {
            res2.Data.forEach((element) => {
              newArray.push({ name: element.parking_name })
            })
          }
          break
        }
        case 3: { // 路外停车场
          const res3 = await systemapi.getlnParkingListByGropId({
            group_id: row._id,
            parktype: 2
          })
          if (res3 && res3.Code === 200 && res3.Data.length > 0) {
            res3.Data.forEach((element) => {
              newArray.push({ name: element.parking_name })
            })
          }
          break
        }
        case 4: { // 监控场景
          const res4 = await systemapi.getMonitorSenceListByGropId({
            group_id: row._id
          })
          console.log(res4)
          if (res4 && res4.Code === 200 && res4.Data.length > 0) {
            res4.Data.forEach((element) => {
              newArray.push({ name: element.scene_name })
            })
          }
          break
        }
        case 5: { // 组织
          const res5 = await systemapi.getOrganizationListByGropId({
            group_id: row._id
          })
          console.log(res5)
          if (res5 && res5.Code === 200 && res5.Data.length > 0) {
            res5.Data.forEach((element) => {
              newArray.push({ name: element.organize_name })
            })
          }
          break
        }
      }
      this.detailDialogData = newArray
      this.detailDialogTitle = title
      this.isDetailDialog = true
    },
    // 新增
    // 编辑
    handelGroupManage (id) {
      this.dialogGroupManageId = id
      this.showGroupManage = true
    },
    closeGroupManage () {
      this.showGroupManage = false
      this.requestGroupList()
    }
  }
}
</script>

<style scoped lang="less">
.table_box {
  /deep/ .el-table__body-wrapper {
    max-height: 431px;
    overflow-y: auto;
  }
}
</style>
