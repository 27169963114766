<template>
  <el-table :data="tableData" :span-method="objectSpanMethod" align="center" header-align="center" tooltip-effect="dark" height="550"
    style="width: 100%" border stripe>
    <el-table-column prop="plate_name" label="板块"> </el-table-column>
    <el-table-column prop="childrenplate_name" label="功能">
    </el-table-column>

    <el-table-column label="显示名称" width="300">
      <template scope="scope">
        <el-input size="mini" v-model="scope.row.show_name" style="width: 100%" placeholder="显示名称"></el-input>
      </template>
    </el-table-column>

    <el-table-column prop="is_open" label="是否开启" width="100">
      <template slot-scope="scope">
        <el-switch v-model="scope.row.is_open" active-color="#13ce66" inactive-color="#ccc">
        </el-switch>
        <!-- <el-checkbox v-model="scope.row.is_open"></el-checkbox> -->
      </template>
    </el-table-column>
    <el-table-column prop="able_personnel" label="适用组织成员" width="500">
      <template slot-scope="scope">
        <el-checkbox-group v-model="scope.row.able_personnel">
          <el-checkbox v-for="item in getOptions('RegisterPersonType')" :key="item.value" :label="item.value"
            :value="item.value">{{ item.name }}</el-checkbox>
          <!-- <el-checkbox v-model="scope.rowis_open"></el-checkbox>
                <el-checkbox v-model="scope.rowis_open"></el-checkbox>
                <el-che../../../api/systemapie.rowis_open"></el-checkbox> -->
        </el-checkbox-group>
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
export default {
  props: {
    list: { type: Array }
  },
  data () {
    return {
      tableData: [],
      spanArr: [], // 用于存放需要合并的行的个数
      spanIndex: 0 // 记录spanArr数组的下标
    }
  },
  created () {
    this.finlterData(this.list)
  },
  mounted () {
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['isAdmin', 'getOptions', 'getWayName'])
  },
  watch: {
    list (val, oldVal) {
      console.log('list--config----', val)
      if (val) {
        this.finlterData(val)
      }
    }
  },
  methods: {
    ...mapMutations(['setQueryStatus']),
    finlterData (data) {
      this.tableData = []
      this.spanArr = [] // 用于存放需要合并的行的个数
      this.spanIndex = 0 // 记录spanArr数组的下标
      // console.log(data);
      data.forEach((d) => {
        d.children.forEach((i) => {
          this.tableData.push({
            plate_name: d.plate_name,
            is_theme: d.is_theme,
            plate_type: d.plate_type,
            plate_sort: d.plate_sort,
            plate_id: i.plate_id,
            childrenplate_name: i.plate_name,
            show_name: i.show_name,
            able_personnel: i.able_personnel,
            childrenis_theme: i.is_theme,
            big_icon: i.big_icon,
            small_icon: i.small_icon,
            is_open: i.is_open,
            childrenplate_sort: i.plate_sort
          })
        })
      })
      this.getSpanArr(this.tableData)
    },
    // 处理数据
    getSpanArr (data) {
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          this.spanArr.push(1)
          this.spanIndex = 0
        } else {
          // 判断当前行与前一行内容是否相同
          if (data[i].plate_type === data[i - 1].plate_type) {
            this.spanArr[this.spanIndex] += 1 // 相同的话，当前下标所代表的值加一，例如：第一列的前三行可合并
            this.spanArr.push(0) // 记录完毕后，再往数组里添加一个元素0，作为下一次合并的初始值
          } else {
            this.spanArr.push(1) // 否则，依旧是一行
            this.spanIndex = i
          }
        }
      }
    },
    // 合并多行单元格的方法
    /**
     * row:表格行
     * column:表格列
     * rowIndex：表格行下标
     * columnIndex：表格列下标
     * */
    objectSpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        const _row = this.spanArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          // [0,0] 表示这一行不显示， [2,1]表示行的合并数
          rowspan: _row,
          colspan: _col
        }
      }
    },
    // app模板数据转换
    Gettabledata () {
      var childrendt = []
      var newArr = []
      this.tableData.forEach((d) => {
        childrendt.push({
          plate_name: d.plate_name,
          is_theme: d.is_theme,
          plate_type: d.plate_type,
          plate_sort: d.plate_sort
        })
      })
      const map = new Map()
      childrendt = childrendt.filter(
        (key) => !map.has(key.plate_type) && map.set(key.plate_type, 1)
      )
      childrendt.forEach((c) => {
        var children = []
        this.tableData.forEach((d) => {
          if (c.plate_type === d.plate_type) {
            children.push({
              plate_id: d.plate_id,
              plate_name: d.childrenplate_name,
              show_name: d.show_name,
              able_personnel: d.able_personnel,
              big_icon: d.big_icon,
              is_theme: d.childrenis_theme,
              small_icon: d.small_icon,
              is_open: d.is_open,
              plate_sort: d.childrenplate_sort
            })
          }
        })
        newArr.push({
          plate_name: c.plate_name,
          is_theme: c.is_theme,
          plate_type: c.plate_type,
          plate_sort: c.plate_sort,
          children: children
        })
      })
      return newArr
    }
  }
}
</script>
<style scoped lang="less">
.addForm {

  /deep/ .el-select,
  /deep/.el-input {
    width: 400px;
    margin-right: 10px;
  }
}
</style>
