<template>
  <lebo-dialog append-to-body title="大屏" :isShow="isConfigScreenCustom" width="95%" top="3vh" @close="closeHandle" class="my_dialog" footerSlot>
    <el-tabs v-model="currentIndex" type="card" editable @tab-remove="removeTabHandle" @tab-add="addTabHandle" @tab-click="handleClick" :before-leave="beforeChange" lazy>
      <el-tab-pane :key="index" v-for="(item, index) in screen_custom" :label="'大屏' + (index + 1)" :name="'' + index" >
      </el-tab-pane>
      <el-tab-pane key="add" name="add" id="add">
        <span slot="label" style="padding: 8px;">
            + 新增
        </span>
    </el-tab-pane>
    </el-tabs>
    <ScreenConfig :data="screen_custom[currentIndex]" @change="screenConfigChange" :isMultiple="true" ref="screenConfig"></ScreenConfig>
    <div slot="footer" class="dialog-footer">
      <lbButton type="warning" fill @click="openScreenTemplate">从模板导入</lbButton>
      <div>
        <!-- <lbButton type="goBack" icon="back" @click="isConfigScreenCustom = false">返 回</lbButton> -->
        <lbButton type="default" fill icon="confirm" @click="onSubmit">保 存</lbButton>
      </div>
    </div>
    <lebo-dialog title="大屏配置模板" append-to-body :isShow="isScreenTemplate" width="30%" @close="isScreenTemplate=false" footerSlot>
      <el-table class="tablestyle" ref="multipleTable" :data="bigScreenTemplateList" align="center" header-align="center" tooltip-effect="dark" border stripe>
        <el-table-column prop="screen_name" label="平台菜单包"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <lbButton type="info" icon="daoru1" hint="确定导入" @click="importBigScreen(scope.row)"></lbButton>
          </template>
        </el-table-column>
      </el-table>
    </lebo-dialog>
  </lebo-dialog>
</template>
<script>
import systemApi from '@/api/systemapi'
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import ScreenConfig from '@/components/screenCustom/screenConfig.vue'
import html2canvas from 'html2canvas'
export default {
  props: {
    screenCustom: { type: Array }
  },
  components: {
    ScreenConfig
  },
  data () {
    return {
      screen_custom: [],
      currentIndex: '0',
      isConfigScreenCustom: true,
      isScreenTemplate: false,
      bigScreenTemplateList: [], // 模板列表
      submitList: [],
      newEmptyScreen: true
    }
  },
  watch: {
    // currentIndex: {
    //   handler (val) {
    //     // this.$emit("change", val)
    //   },
    //   deep: true
    // }
  },
  created () {
    this.$nextTick(() => {
      if (this.screenCustom.length > 0) {
        this.screen_custom = this.screenCustom.concat()
        this.newEmptyScreen = false
        // setTimeout(() => {
        // this.screen_custom[0] = this.screenCustom[0]
        // }, 200)
      } else {
        // 没有大屏数据默认添加一个空的数据
        this.addTabHandle()
        this.newEmptyScreen = true
      }
      const tabAdd = document.getElementById('tab-add')
      tabAdd.lastElementChild.style.display = 'none'
    })
  },
  mounted () { },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['isAdmin', 'getOptions', 'getWayName'])
  },
  methods: {
    async openScreenTemplate () {
      const res = await systemApi.getBigScreenTemplate()
      this.bigScreenTemplateList = res && res.Code === 200 ? res.Data : []
      this.isScreenTemplate = true
    },
    // 导入
    importBigScreen (item) {
      this.screen_custom[this.currentIndex] = item
      this.isScreenTemplate = false
    },
    // 移除tab
    removeTabHandle (index) {
      this.screen_custom.splice(Number(index), 1)
      this.currentIndex = Number(this.currentIndex) - 1 + ''
      // 删除所有大屏 -跳转到编辑集团页面
      if (this.screen_custom.length === 0) {
        this.screen_custom = []
        this.currentIndex = 0
        this.isConfigScreenCustom = false
        this.$emit('removeAll')
      }
    },
    // 新增tab
    async addTabHandle () {
      // 必须异步否则会截不到
      // 触发当前屏幕截图
      await this.$refs.screenConfig.catchMapPic()
      this.screen_custom.push({
        screen_name: '', // 大屏名称
        screen_type: 1, // 大屏类型  地图版本 = 1, 报表版本 = 2
        screen_model: 1, // 大屏模式 深色模式 = 1, 浅色模式 = 2
        map_lng: '', // 地图经度
        map_lat: '', // 地图纬度
        map_zoom: 5, // 地图的缩放层级
        children: [],
        screen_display_type: 1,
        screen_pic: ''
      })
      // setTimeout(() => {
      this.currentIndex = this.screen_custom.length - 1 + ''
      // 第一次会添加两个手动删除一个
      if (this.newEmptyScreen) {
        this.removeTabHandle(1)
        this.newEmptyScreen = false
      }
      // }, 20)
    },
    // 保存
    onSubmit () {
      // 若不是3D大屏则清除配置的地址
      if (this.screen_custom[0].screen_display_type === 1) {
        this.screen_custom[0].screen_3D_domain = ''
      }
      for (var index = 0; index < this.screen_custom.length; index++) {
        const item = this.screen_custom[index]
        if (item.screen_name.trim().length === 0) {
          this.$msg.warning(`大屏${index + 1}名称不能为空`)
          return
        }
      }
      // 触发当前屏幕截图
      this.$refs.screenConfig.catchMapPic()
      console.log('this.screen_custom', this.screen_custom)
      this.$emit('save', this.screen_custom)
      this.isConfigScreenCustom = false
    },
    closeHandle () {
      this.$emit('close')
      this.isConfigScreenCustom = false
    },
    // 大屏配置修改时触发事件
    screenConfigChange (data) {
      // console.log('大屏修改', data)
      this.screen_custom[this.currentIndex] = data
    },
    // 切换大屏触发
    handleClick () {
    },
    // 离开当前tab之前触发
    async beforeChange (currentName, oldName) {
      // 触发当前屏幕截图
      await this.$refs.screenConfig.catchMapPic()
      console.log('change', this.screen_custom)
      // 重点，如果name是add，则什么都不触发
      if (currentName === 'add') {
        this.addTabHandle()
        return false
      }
    }
  }
}
</script>
<style scoped lang="less">
.my_dialog {

  /deep/ .el-dialog__body {
    min-height: 400px;

    padding: 10px 10px;
  }
  /deep/ .dialog-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.tablestyle {
  /deep/ .el-table__body-wrapper {
    max-height: 500px;
    overflow-y: auto;
  }
}
/deep/.el-tabs__new-tab{
  display: none;
}
</style>
